import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokContainerLinkType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

export const StoryblokContainerLink: FC<
  StoryblokComponentProps<StoryblokContainerLinkType>
> = ({ blok }) => {
  const { content, display, href } = blok

  return (
    <a
      {...storyblokEditable(blok)}
      className={clsx(display === 'block' && 'block')}
      href={href?.url}
      rel={href?.target === '_blank' ? 'noreferrer' : undefined}
      target={href?.target}
    >
      <StoryblokComponentsRenderer bloks={content} />
    </a>
  )
}
